// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable-next-line selector-class-pattern */
.react-flow__node-toolbar {
    position: fixed !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomNodes/NodeToolBarWrapper.css"],"names":[],"mappings":"AAAA,uDAAuD;AACvD;IACI,0BAA0B;AAC9B","sourcesContent":["/* stylelint-disable-next-line selector-class-pattern */\n.react-flow__node-toolbar {\n    position: fixed !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
