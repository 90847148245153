import { jsx as _jsx } from "react/jsx-runtime";
import { NodeToolbar, Position } from "@xyflow/react";
import { useEffect, useRef, useState } from "react";
import "./NodeToolBarWrapper.css";
const NodeToolbarWrapper = (props) => {
    const { align, children, className, isVisible, nodeId, offset, position, ...rest } = props;
    const nodeToolBarRef = useRef(null);
    const [newPosition, setNewPosition] = useState(Position.Left);
    const adjustToolTipPosition = (tooltip) => {
        const boundingRect = tooltip.getBoundingClientRect();
        const containerRect = tooltip.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getBoundingClientRect();
        let pos;
        if (!containerRect) {
            return;
        }
        if ((boundingRect.x > containerRect.width && boundingRect.right < containerRect.right) ||
            boundingRect.right + boundingRect.width > containerRect.right) {
            pos = Position.Left;
        }
        else {
            pos = Position.Right;
        }
        setNewPosition(pos);
    };
    useEffect(() => {
        if (nodeToolBarRef.current && isVisible) {
            adjustToolTipPosition(nodeToolBarRef.current);
        }
    }, [isVisible, position, offset, align]);
    return (_jsx("div", { className: "toolbar-wrapper", ref: nodeToolBarRef, children: _jsx(NodeToolbar, { className: className, isVisible: isVisible, nodeId: nodeId, offset: offset, position: newPosition, ...rest, children: children }) }));
};
export default NodeToolbarWrapper;
